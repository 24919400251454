// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-es-js": () => import("./../../../src/pages/about.es.js" /* webpackChunkName: "component---src-pages-about-es-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-growth-same-day-js": () => import("./../../../src/pages/growth/same-day.js" /* webpackChunkName: "component---src-pages-growth-same-day-js" */),
  "component---src-pages-growth-same-day-pt-js": () => import("./../../../src/pages/growth/same-day.pt.js" /* webpackChunkName: "component---src-pages-growth-same-day-pt-js" */),
  "component---src-pages-growth-thanks-js": () => import("./../../../src/pages/growth/thanks.js" /* webpackChunkName: "component---src-pages-growth-thanks-js" */),
  "component---src-pages-growth-thanks-pt-js": () => import("./../../../src/pages/growth/thanks.pt.js" /* webpackChunkName: "component---src-pages-growth-thanks-pt-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-pt-js": () => import("./../../../src/pages/index.pt.js" /* webpackChunkName: "component---src-pages-index-pt-js" */),
  "component---src-pages-lives-new-normal-js": () => import("./../../../src/pages/lives/new-normal.js" /* webpackChunkName: "component---src-pages-lives-new-normal-js" */),
  "component---src-pages-lives-omnicanalidad-js": () => import("./../../../src/pages/lives/omnicanalidad.js" /* webpackChunkName: "component---src-pages-lives-omnicanalidad-js" */),
  "component---src-pages-lives-retailers-lastmile-js": () => import("./../../../src/pages/lives/retailers-lastmile.js" /* webpackChunkName: "component---src-pages-lives-retailers-lastmile-js" */),
  "component---src-pages-lives-retailers-pharma-js": () => import("./../../../src/pages/lives/retailers-pharma.js" /* webpackChunkName: "component---src-pages-lives-retailers-pharma-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

